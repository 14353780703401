import React from "react";
import './App.css';

/**************************/
//APP STRUCTURE...
//<GuitarFretboardApp>
//	<Fretboard>
//		<FretGrid />
//		<RootNotes />
//		<Notes />
//		<NoteLabels />
//	</Fretboard>
//	<FretLengthControl />
//	<ScaleTypeControl />
//	<ScaleDensityControl />
//	<LabelDensityControl />
//</GuitarFretboardApp>
/**************************/

function App() {
	
	const captions = [
		['Root notes', 'Root / Diminished Fifth', 'Diminished Triad', 'Diminished 7th','Whole-Half Diminished Scale','Half-Whole Diminished Scale'],
		['Root notes','Root / Fifth','Minor Triad','Minor Pentatonic Scale','[Natural] Minor Scale (Diatonic)'],
		['Root notes','Root / Fifth','Major Triad','Major Pentatonic Scale','Major Scale (Diatonic)'],
		['Root notes','Root / Augmented Fifth','Augmented Triad','Whole Tone Scale']
	];
	
	const notes_flat = ['A','B♭','B','C','D♭','D','E♭','E','F','G♭','G','A♭'];
	const notes_sharp = ['A','A♯','B','C','C♯','D','D♯','E','F','F♯','G','G♯'];
	
	const dim0 = ['','','','','','','','','','','',''];
	const dim1 = ['','',1,'','','','','','','','',''];
	const dim2 = ['','',1,'','','','','',5,'','',''];
	const dim3 = ['','',1,'','',3,'','',5,'','',''];
	const dim4 = ['','',1,'','',3,'','',5,'','',7];
	const dim5 = ['',8,1,'',2,3,'',4,5,'',6,7];
	const dim6 = [8,'',1,2,'',3,4,'',5,6,'',7];
	
	const min0 = ['','','','','','','','','','','',''];
	const min1 = [1,'','','','','','','','','','',''];
	const min2 = [1,'','','','','','',5,'','','',''];
	const min3 = [1,'','',3,'','','',5,'','','',''];
	const min4 = [1,'','',3,'',4,'',5,'','',7,''];
	const min5 = [1,'',2,3,'',4,'',5,6,'',7,''];
	
	const maj0 = ['','','','','','','','','','','',''];
	const maj1 = ['','','',1,'','','','','','','',''];
	const maj2 = ['','','',1,'','','','','','',5,''];
	const maj3 = ['','','',1,'','','',3,'','',5,''];
	const maj4 = [6,'','',1,'',2,'',3,'','',5,''];
	const maj5 = [6,'',7,1,'',2,'',3,4,'',5,''];
	
	const aug0 = ['','','','','','','','','','','',''];
	const aug1 = ['','','',1,'','','','','','','',''];
	const aug2 = ['','','',1,'','','','','','','',5];
	const aug3 = ['','','',1,'','','',3,'','','',5];
	const aug4 = ['',6,'',1,'',2,'',3,'',4,'',5];
	
	function getScaleName(scaleType,scaleName) {
		switch (scaleType) {
			case '1' : 
			scaleName = 'dim';
			break;
			case '2' : 
			scaleName = 'min';
			break;
			case '3' : 
			scaleName = 'maj';
			break;
			case '4' : 
			scaleName = 'aug';
			break;
		}
		return scaleName;
	}
	
	function setScaleDensityControl(scaleType,scaleDensity,labelDensity,scaleDensityMax) {
		switch (scaleType) {
			case '1' : 
			scaleDensityMax = '6';
			if (scaleDensity == 6 && labelDensity == 5) labelDensity = 6;
			break;
			case '2' : 
			if (scaleDensity > 5) scaleDensity = 5;
			scaleDensityMax = '5';
			break;
			case '3' : 
			if (scaleDensity > 5) scaleDensity = 5;
			scaleDensityMax = '5';
			break;
			case '4' : 
			if (scaleDensity > 4) scaleDensity = 4;
			scaleDensityMax = '4';
			break;
		}
		return {
			scaleDensity,
			scaleDensityMax,
			labelDensity
		};
	}
	
	class GuitarFretboardApp extends React.Component {
		constructor(props) {
			super(props);
			this.handleFretLengthChange = this.handleFretLengthChange.bind(this);
			this.handleScaleTypeChange = this.handleScaleTypeChange.bind(this);
			this.handleScaleDensityChange = this.handleScaleDensityChange.bind(this);
			this.handleLabelDensityChange = this.handleLabelDensityChange.bind(this);
			this.handleShiftChange = this.handleShiftChange.bind(this);
			this.state = {frets: 3, scaleDensity: 3,  scaleDensityMax: '5', scaleType: '2', scaleName: 'min', labelDensity: 3, shift: 'parallel'};
		}
		
		handleFretLengthChange(frets) {
			this.setState({frets});
		}
		
		handleScaleTypeChange(scaleType,scaleName,scaleDensity,scaleDensityMax,labelDensity) {
			let scaleDensityArray = setScaleDensityControl(scaleType,this.state.scaleDensity,this.state.labelDensity);
			scaleDensity = scaleDensityArray.scaleDensity;
			scaleDensityMax = scaleDensityArray.scaleDensityMax;
			labelDensity = scaleDensityArray.labelDensity;
			if(labelDensity > scaleDensity) {
				labelDensity = scaleDensity;
			}
			this.setState({scaleType});
			this.setState({scaleName});
			this.setState({scaleDensity});
			this.setState({scaleDensityMax});
			this.setState({labelDensity});
		}
		
		handleScaleDensityChange(scaleDensity,scaleDensityMax,labelDensity) {
			let scaleDensityArray = setScaleDensityControl(this.state.scaleType,scaleDensity,this.state.labelDensity);
			scaleDensity = scaleDensityArray.scaleDensity;
			scaleDensityMax = scaleDensityArray.scaleDensityMax;
			labelDensity = scaleDensityArray.labelDensity;
			if(labelDensity > scaleDensity) {
				labelDensity = scaleDensity;
				this.handleLabelDensityChange(labelDensity,scaleDensity);
			}
			this.setState({scaleDensity});
			this.setState({scaleDensityMax});
			this.setState({labelDensity});
		}
		
		handleLabelDensityChange(labelDensity,scaleDensity,scaleType) { 
			scaleDensity = this.state.scaleDensity;
			scaleType = this.state.scaleType;
			if(scaleType ==='1') { // handles both WH & HW diminished scales with 8 notes...
				if (scaleDensity == 6 && labelDensity == 5) {
					labelDensity = 6;
				}
			}
			this.setState({labelDensity});
		}
		
		handleShiftChange(shift) {
			this.setState({shift});
		}
		
		render() {
			const frets = parseInt(this.state.frets);
			const scaleType = this.state.scaleType;
			const scaleDensity = this.state.scaleDensity;
			const scaleDensityMax = this.state.scaleDensityMax;
			const scaleName = getScaleName(scaleType);
			const scale = eval(scaleName+scaleDensity+[]);
			const labelDensity = this.state.labelDensity;
			const labelScale = eval(scaleName+labelDensity+[]);
			const shift = this.state.shift;
			
			return (
				<div id="fretboardWrapper">
					<Fretboard frets={frets} scale={scale} scaleType={scaleType} labelScale={labelScale} labelDensity={labelDensity} shift={shift} />
					<Caption scaleType={scaleType} density={scaleDensity} />
					<FretLengthControl value={frets} onFretLengthChange={this.handleFretLengthChange} />
					<ScaleTypeControl value={scaleType} onScaleTypeChange={this.handleScaleTypeChange} />
					<ScaleDensityControl value={scaleDensity} max={scaleDensityMax} onScaleDensityChange={this.handleScaleDensityChange} />
					<LabelDensityControl value={labelDensity} scaleDensity={scaleDensity} max={scaleDensity} onLabelDensityChange={this.handleLabelDensityChange} />
					<ShiftControl value={shift} onShiftChange={this.handleShiftChange} />
				</div>
			);
		}
	}
	
	class Fretboard extends React.Component {
		constructor(props) {
			super(props);
		}
		render() {
			const frets = this.props.frets;
			const scale = this.props.scale;
			const scaleType = this.props.scaleType;
			const labelScale = this.props.labelScale;
			const labelDensity = this.props.labelDensity;
			const shift = this.props.shift;
			
			return (
				<div id="fretboardContainer">
					<svg id="svgContainer" height="320" width={(frets * 100) + 100}>
						<FretGrid frets={frets} />
						<RootNotes frets={frets} scale={scale} scaleType={scaleType} shift={shift} />
						<Notes frets={frets} scale={scale} scaleType={scaleType} shift={shift} />
						<NoteLabels frets={frets} labelScale={labelScale} scaleType={scaleType} shift={shift} />
						Sorry, your browser does not support inline SVG.
					</svg>
				</div>
			);
		}
	}
		
	class FretGrid extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			const frets = this.props.frets;
			return (
				Array(5).fill().map((fretVal, ii) =>
					<g stroke="black" strokeWidth="3" fill="none" key={'fret'+(ii+1)}>
						{Array(frets).fill().map((rowVal, i) => <rect id={'fret'+(ii+1)+'_'+(i+1)} key={'fret'+(ii+1)+'_'+(i+1)} x={(i*100)+50} y={(ii*50)+50} width="100" height="50" /> )}
					</g>
				)
			);
		}
	}
	
	function standardTuning(notePosition,stringNum,scaleType,shift) {
		switch (stringNum) {
			case 1 : 
			notePosition += 7;
			break;
			case 2 : 
			notePosition += 3;
			break;
			case 3 : 
			notePosition += 10;
			break;
			case 4 : 
			notePosition += 5;
			break;
		}
		if (shift === 'parallel') notePosition = parallelShift(notePosition,scaleType);
		while (notePosition > 11) notePosition -= 12;
		return (
			notePosition
		)
	}
	
	function parallelShift(notePosition,scaleType) {
		switch (scaleType) {
			case '1' : 
			notePosition += 2;
			break;
			case '2' : 
			//Minor scale does not shift
			break;
			case '3' : 
			notePosition += 3;
			break;
			case '4' : 
			notePosition += 3;
			break;
		}
		return (
			notePosition
		)
	}
	
	class RootNotes extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			const frets = this.props.frets;
			const scale = this.props.scale;
			const scaleType = this.props.scaleType;
			const shift = this.props.shift;
			
			function getVisibility(position,stringNum,scale,scaleType,shift) {
				let note = standardTuning(position,stringNum,scaleType,shift);
				return (
					(scale[note] === 1) && (scaleType != '1') && (scaleType != '4') ? 'visible' : 'invisible'
				)
			}
			
			return (
				// KEY: ii = stringNum, i = position
				Array(6).fill().map((rootVal, ii) =>
					<g stroke="black" strokeWidth="2" fill="none" key={'root'+(ii+1)}>
						{Array(frets).fill().map((rowVal, i) => <circle id={'root'+(ii+1)+'_'+(i+1)} key={'root'+(ii+1)+'_'+(i+1)} className={getVisibility(i,ii,scale,scaleType,shift)} cx={(i*100)+100} cy={(ii*50)+50} r="15" /> )}
					</g>
				)
			);
		}
	}
	
	class Notes extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			const frets = this.props.frets;
			const scale = this.props.scale;
			const scaleType = this.props.scaleType;
			const shift = this.props.shift;
			
			function getVisibility(position,stringNum,scaleType,shift) {
				let note = standardTuning(position,stringNum,scaleType,shift);
				return (
					scale[note] ? 'visible' : 'invisible'
				)
			}
			
			return (
				// KEY: ii = stringNum, i = position
				Array(6).fill().map((noteVal, ii) =>
					<g stroke="none" fill="black" key={'note'+(ii+1)}>
						{Array(frets).fill().map((rowVal, i) => <circle id={'note'+(ii+1)+'_'+(i+1)} key={'note'+(ii+1)+'_'+(i+1)} className={getVisibility(i,ii,scaleType,shift)} cx={(i*100)+100} cy={(ii*50)+50} r="10" /> )}
					</g>
				)
			);
		}
	}
	
	class NoteLabels extends React.Component {
		constructor(props) {
			super(props);
		}
		
		render() {
			const frets = this.props.frets;
			const scaleType = this.props.scaleType;
			const labelScale = this.props.labelScale;
			const shift = this.props.shift;
			
			function getVisibility(position,stringNum,scaleType,shift) {
				let noteLabel = standardTuning(position,stringNum,scaleType,shift);
				return (
					labelScale[noteLabel] ? 'visible' : 'invisible'
				)
			}
			
			function getLabel(position,stringNum,scaleType,shift) {
				const numLabel = standardTuning(position,stringNum,scaleType,shift);
				return (
					labelScale[numLabel]
				)
			}
			
			return (
				// KEY: ii = stringNum, i = position
				Array(6).fill().map((labelVal, ii) =>
					<g fontSize="1rem" fontFamily="sans-serif" fill="#ffffff" stroke="none" textAnchor="middle" key={'label'+(ii+1)}>
						{Array(frets).fill().map((rowVal, i) => <text id={'label'+(ii+1)+'_'+(i+1)} key={'label'+(ii+1)+'_'+(i+1)} className={getVisibility(i,ii,scaleType,shift)} x={(i*100)+100} y={(ii*50)+50} dx="0" dy="6">{getLabel(i,ii,scaleType,shift)}</text> )}
					</g>
				)
			);
		}
	}
	
	class Caption extends React.Component {
		constructor(props) {
			super(props);
		}

		render() {
			const scaleType = this.props.scaleType - 1;
			const density = this.props.density - 1;
			const caption = eval('captions['+scaleType+']['+density+']');
			
			return (
				<h2 id="caption">{caption}</h2>
			);
		}
	}
	
	class FretLengthControl extends React.Component {
		constructor(props) {
			super(props);
			this.handleChange = this.handleChange.bind(this);
		}

		handleChange(e) {
			this.props.onFretLengthChange(e.target.value);
		}

		render() {
			const frets = this.props.value;
			return (
				<div id="lengthSelectorSection">
					<form id="lengthSelector">
						<label id="fretboardLengthLabel" htmlFor="fretboardLengthRange" >Fretboard Length</label><br />
						<input id="fretboardLengthRange" type="range" defaultValue={frets} onChange={this.handleChange} min="3" max="25" /> 
					</form>
				</div>
			);
		}
	}
	
	class ScaleTypeControl extends React.Component {
		constructor(props) {
			super(props);
			this.handleChange = this.handleChange.bind(this);
		}

		handleChange(e) {
			this.props.onScaleTypeChange(e.target.value);
		}

		render() {
			const scaleType = this.props.value;
			return (
				<div id="scaleTypeSelectorSection">
					<form id="scaleTypeSelector">
						<label id="scaleTypeLabel" htmlFor="scaleTypeRange" >Dim7 - Min - Maj - Aug</label><br />
						<input id="scaleTypeRange" type="range" defaultValue={scaleType} onChange={this.handleChange} min="1" max="4" /> 
					</form>
				</div>
			);
		}
	}
	
	class ScaleDensityControl extends React.Component {
		constructor(props) {
			super(props);
			this.handleChange = this.handleChange.bind(this);
		}

		handleChange(e) {
			this.props.onScaleDensityChange(e.target.value);
		}

		render() {
			const scaleDensity = this.props.value;
			const scaleDensityMax = this.props.max;
			return (
				<div id="densitySelectorSection">
					<form id="densitySelector">
						<label id="scaleDensityLabel" htmlFor="scaleDensityRange" >Scale Tones</label><br />
						<input id="scaleDensityRange" type="range" defaultValue={scaleDensity} onChange={this.handleChange} min="1" max={scaleDensityMax} /> 
					</form>
				</div>
			);
		}
	}
	
	class LabelDensityControl extends React.Component {
		constructor(props) {
			super(props);
			this.handleChange = this.handleChange.bind(this);
		}

		handleChange(e) {
			this.props.onLabelDensityChange(e.target.value);
		}

		render() {
			const labelDensity = this.props.value;
			const labelDensityMax = this.props.max;
			return (
				<div id="labelSelectorSection">
					<form id="labelSelector">
						<label id="labelDensityLabel" htmlFor="noteLabelRange" >Labels</label><br />
						<input id="labelDensityRange" type="range" defaultValue={labelDensity} onChange={this.handleChange} min="0" max={labelDensityMax} />
					</form>
				</div>
			);
		}
	}
	
	class ShiftControl extends React.Component {
		constructor(props) {
			super(props);
			this.handleChange = this.handleChange.bind(this);
		}

		handleChange(e) {
			this.props.onShiftChange(e.target.value);
		}

		render() {
			const shift = this.props.value;
			
			return (
				<div id="shiftSelectorSection">
					<form id="shiftSelector">
						<input id="relativeOption" type="radio" name="shift" value="relative" checked={shift==="relative"} onChange={this.handleChange} />
						<label id="relativeLabel" htmlFor="relativeOption" >Relative</label>
						<input id="parallelOption" type="radio" name="shift" value="parallel" checked={shift==="parallel"} onChange={this.handleChange} />
						<label id="labelDensityLabel" htmlFor="parallelOption" >Parallel</label>
					</form>
				</div>
			);
		}
	}
	
  return (
    <div className="App">
			<div id="logoWrapper">
				<div id="logo">Music Hacker</div>
				<div id="tagline">Tools for Musicians</div>
			</div>
			<h1>Guitar Fretboard Visualizer</h1>
			
			<GuitarFretboardApp />
			
			<div className="footer">
				&copy; 2021 <strong>360 Digital Arts</strong>. All Rights Reserved. App design by Mark A. Engel.
			</div>
    </div>

  );
}

export default App;
